import React, {useContext, useEffect, useState} from "react";
import {MapContainer, TileLayer, useMapEvents} from "react-leaflet";
import {LatLng, LatLngBounds, Map as LeafletMap} from "leaflet";
import {v4 as uuidv4} from "uuid";

import CustomMarker from "./CustomMarker";
import {CaseStatus} from "../models/CaseStatus";
import {FilterCaseStatus} from "../models/FilterCaseStatus";

import "leaflet/dist/leaflet.css";
import "../styles/map.css";
import {DefaultUserMarker, UserMarker} from "../models/UserMarker";
import {toast} from "react-toastify";
import FilterComponent from "./FilterComponent";
import {sendEmail} from "./ErrorBoundary";
import MarkerModal from "./modals/MarkerModal";
import MarkerInfoModal from "./modals/MarkerInfoModal";
import UserContext from "../contexts/UserContext";
import {getBackendUrl} from "../scripts/utils";
import {GeocodeData} from "./modals/GeocodeData";
import MapSearchBar from "./MapSearchBar";
import {getUserAccessToken} from "../scripts/storageUtils";
import LoaderCar from "./LoaderCar";

type AddMarkerProps = {
    onMarkerAdd: (latlng: LatLng) => void;
}

const AddMarkerToClick: React.FC<AddMarkerProps> = ({ onMarkerAdd }) => {
    useMapEvents({
        click(e) {
            onMarkerAdd(e.latlng);
        },
    });

    return null;
};

const Map: React.FC = () => {
    const [markers, setMarkers] = useState<UserMarker[]>([]);
    const [currentMarker, setCurrentMarker] = useState<UserMarker | undefined>(undefined);
    const [cachedMarkers, setCachedMarkers] = useState<UserMarker[]>([]);
    const [filterState, setFilterState] = useState<string>(FilterCaseStatus.all);

    const [userSearchLocation, setUserSearchLocation] = useState<GeocodeData>();

    const [showMarkerModal, setShowMarkerModal] = useState(false);
    const [showMarkerInfoModal, setShowMarkerInfoModal] = useState(false);

    const mapRef = React.createRef<LeafletMap>();
    const userContext = useContext(UserContext);

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setLoading(true);
        const fetchIssues = async () => {
            let issues: UserMarker[] = [];
            try {
                const response = await fetch(getBackendUrl("getIssues"));
                issues = await response.json();
                setMarkers(issues || []);
                setCachedMarkers(issues || []);
                setFilterState(FilterCaseStatus.all);
            }
            catch (error) {
                const err = error as Error;
                toast.error("Wystąpił błąd podczas wczytywania zgłoszeń. Spróbuj ponownie później.");
                sendEmail(err?.message);
            }
        };

        fetchIssues().then(() => setLoading(false));
    }, []);

    const handleMarkerAdd = (latlng: LatLng) => {
        const pixelPosition = mapRef.current?.latLngToContainerPoint(latlng);

        if (pixelPosition) {
            const newMarker: UserMarker = {
                ...DefaultUserMarker,
                id: uuidv4(),
                lat: latlng.lat,
                lng: latlng.lng,
                date: new Date().toISOString(),
                status: CaseStatus.reported,
            };

            setCurrentMarker(newMarker);

            handleOpenShowMarkerModal();
        }
    };

    const handleMarkerClick = async (marker: UserMarker) => {
        setLoading(true);
        if(marker?.status !== CaseStatus.reported && !userContext?.isAdmin()) {

            marker = await fetch(`${getBackendUrl("getIssue")}?id=${marker?.id}`)
                .then(async response => {
                    if (response.status !== 200) {
                        toast.error("Wystąpił błąd. Spróbuj ponownie później.");
                        return;
                    }

                    handleOpenShowMarkerInfoModal();
                    return await response.json();
                })
                .catch(() => {
                    toast.error("Wystąpił błąd. Spróbuj ponownie później.");
                });

            handleOpenShowMarkerInfoModal();
            setCurrentMarker(marker);
        }

        else if (userContext?.isAdmin())
        {
            const userAccessToken = getUserAccessToken();

            if (!userAccessToken) {
                toast.error("Nie jesteś zalogowany lub twój token dostępu wygasł");
                return;
            }

            marker = await fetch(`${getBackendUrl("adminGetIssue")}?id=${marker?.id}&accessToken=${userAccessToken}`)
                .then(async response => {
                    if (response.status !== 200) {
                        toast.error("Wystąpił błąd. Spróbuj ponownie później.");
                        return;
                    }

                    return await response.json();
                })
                .catch((err) => {
                    toast.error("Wystąpił błąd. Spróbuj ponownie później." + err);
                });

            handleOpenShowMarkerInfoModal();
            setCurrentMarker(marker);
        }

        setLoading(false);
    };

    const handleFilter = (filteredMarkers: UserMarker[]) => {
        setMarkers(filteredMarkers);
    };

    function addMarker(marker: UserMarker) {
        setCurrentMarker(marker);

        const updatedMarkers = [...cachedMarkers, marker];

        setCachedMarkers(updatedMarkers);
        setMarkers(updatedMarkers);
        setFilterState(FilterCaseStatus.all);
    }

    const updateCurrentMarkerState = (updatedMarker: UserMarker) => {

        setCurrentMarker(updatedMarker);

        const updatedMarkers = cachedMarkers.map(marker => {
            if(marker.id === updatedMarker.id) {
                return updatedMarker;
            }
            return marker;
        });

        setCachedMarkers(updatedMarkers);
        setMarkers(updatedMarkers);
        setFilterState(FilterCaseStatus.all);
    };

    const updateFilterState = (newFilterState: string) => {
        setFilterState(newFilterState);
    }

    const handleOpenShowMarkerInfoModal = () => setShowMarkerInfoModal(true);
    const handleCloseShowMarkerInfoModal = () => setShowMarkerInfoModal(false);

    const handleOpenShowMarkerModal = () => setShowMarkerModal(true);
    const handleCloseShowMarkerModal = () => setShowMarkerModal(false);

    const renderMarkerBasedOnModalState = () =>{
        if(currentMarker === undefined){
            return <></>;
        }

        if(showMarkerInfoModal) {
            return <MarkerInfoModal
                show={showMarkerInfoModal}
                onClose={handleCloseShowMarkerInfoModal}
                currentMarker={currentMarker}
                onUpdateCurrentMarker={updateCurrentMarkerState}
            />;
        }
        if (showMarkerModal) {
            return  <MarkerModal
                show={showMarkerModal}
                onClose={handleCloseShowMarkerModal}
                currentMarker={currentMarker}
                onAddMarker={addMarker}
            />;
        }
    }

    const southWest = new LatLng(54.0, 18.1),
        northEast = new LatLng(55.0, 19.3),
        bounds = new LatLngBounds(southWest, northEast);

    const handleSearchLocation = (geocodeData: GeocodeData) => {
        setUserSearchLocation(geocodeData);
    }

    useEffect(() => {
        if(userSearchLocation) {
            mapRef.current?.setView(userSearchLocation.geometry.location, 17);
        }
    }, [userSearchLocation]);

    return (
        <>
            {loading && <LoaderCar />}
            <FilterComponent
                markers={cachedMarkers}
                filterState={filterState}
                setFilterState={updateFilterState}
                onApplyFilter={handleFilter} />

            <MapSearchBar
                onSearchLocation={handleSearchLocation}
            />

            <MapContainer
                ref={mapRef as React.RefObject<LeafletMap>}
                center={[54.39, 18.64]}
                zoom={12}
                minZoom={11}
                scrollWheelZoom={true}
                id={"map-container"}
                maxBounds={bounds}
            >
                <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                <AddMarkerToClick onMarkerAdd={handleMarkerAdd} />

                {markers.map((marker, index) => (
                    <CustomMarker key={index} marker={marker} handleMarkerClick={handleMarkerClick} />
                ))}

                {renderMarkerBasedOnModalState()}

            </MapContainer>
        </>
    );
};

export default Map;
