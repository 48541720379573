import {ContextUser} from "../models/ContextUser";


export const getUserFromLocalStorage = (): ContextUser | undefined => {
    const storedUser = localStorage.getItem("user");
    if (!storedUser) {
        return undefined;
    }

    let user: ContextUser;
    try {
        user = JSON.parse(storedUser);
    } catch (err) {
        return undefined;
    }

    return user;
};

export const getUserAccessToken = (): string | undefined => {
    const user = getUserFromLocalStorage();
    return user?.accessToken;
}