import React, {useEffect, useState} from "react";
import UserContext from "./contexts/UserContext";
import { ContextUser } from "./models/ContextUser";
import { ToastContainer } from "react-toastify";
import {StatusCodes} from "http-status-codes";

import MyNavbar from "./components/Navbar";
import Map from "./components/Map";
import CookieConsentBanner from "./components/CookieConsentBanner";

import "./styles/MarkerModal.css";
import "./styles/app.css";
import "react-toastify/dist/ReactToastify.css";

import {ErrorInfo} from "./components/common/ErrorInfo";
import {useLocation} from "react-router-dom";
import {getBackendUrl} from "./scripts/utils";
import {getUserFromLocalStorage} from "./scripts/storageUtils";

const App: React.FC = () => {

    const [user, setUser] = useState<ContextUser | null>(null);
    const [isAdminView, setIsAdminView] = useState<boolean>(false);
    const [isLocationValid, setIsLocationValid] = useState<boolean>(true);

    const location = useLocation();

    useEffect(() => {
        const fetchData = async () => {

            try {
                const user = getUserFromLocalStorage();

                if (!user) return;

                const tokenVerificationResponse = await fetch(`${getBackendUrl("accessTokenLogin")}?accessToken=${user.accessToken}`);

                if (tokenVerificationResponse && tokenVerificationResponse.status === StatusCodes.OK) {
                    setUser(user);
                }
                else {
                    localStorage.removeItem("user");
                }
            }
            catch (error) {
                console.error("Unable to fetch user data. Removing it from local storage.");
            }
        };

        if (location.pathname === '/') {
            setIsAdminView(false);
        }
        else if (location.pathname === '/admin') {
            setIsAdminView(true);
            fetchData().then();
        }
        else {
            setIsLocationValid(false);
        }

    }, [location.pathname]);

    const isAdmin = () => user?.role === "admin";

    const navigateToGdanskReporter = () => {
        window.location.href = window.location.origin + window.location.pathname;
    };

    return (

        <UserContext.Provider value={{user, setUser, isAdmin}}>
            {isLocationValid
                ?
                <>
                    <ToastContainer/>
                    <CookieConsentBanner/>
                    <div className={"app-grid"}>

                        <header className={"app-header"}>
                            <MyNavbar isAdminView={isAdminView}/>
                        </header>

                        <main className={"app-main"}>
                            <Map/>
                        </main>

                        <footer className={"app-footer"}>
                            <a href={"https://www.facebook.com/inicjatywakierowcow"}> Facebook Trójmiejskiej Inicjatywy Kierowców</a>
                        </footer>

                    </div>
                </>
                :
                <div style={{ textAlign: "center" }}>
                    <h3>Niepoprawny adres url</h3>
                    <ErrorInfo/><br/>
                    <button onClick={navigateToGdanskReporter} className={'custom-redirect-button'}>Strona Główna</button>
                </div>
            }
        </UserContext.Provider>
    );
};

export default App;
