import React, {FC, useContext, useState} from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Carousel from "react-bootstrap/Carousel";
import UserContext from "../../contexts/UserContext";

import {toast} from "react-toastify";

import {Document} from "../../models/Document";
import {StatusCodes} from "http-status-codes";
import {UserMarker} from "../../models/UserMarker";

import MarkerManager from "../MarkerManager";
import DocumentPreview from "../form-components/DocumentPreview";

import "../../styles/MarkerModal.css";
import "../../styles/markerInfoModal.css";
import {CaseStatus} from "../../models/CaseStatus";
import {getBackendUrl} from "../../scripts/utils";
import {getUserAccessToken} from "../../scripts/storageUtils";
import {MdClose} from "react-icons/md";
import LoaderCar from "../LoaderCar";

interface Props {
    show: boolean;
    onClose: () => void;
    currentMarker: UserMarker | undefined;
    onUpdateCurrentMarker: (marker: UserMarker) => void;
}

const InfoRow: FC<{ label: string, value?: string }> = ({ label, value }) => (
    <p className="text-break"><strong>{label}:</strong> {value || ""}</p>
);

const MarkerInfoModal: FC<Props> = ({ show, onClose, currentMarker, onUpdateCurrentMarker }) => {
    const [showImageCarousel, setShowImageCarousel] = useState(false);
    const [previewedDocumentUrl, setPreviewedDocumentUrl] = useState<string | null>(null);

    const [loading, setLoading] = useState<boolean>(false);

    const userContext = useContext(UserContext);

    const handleClose = () => {
        onClose();
    };
    const handleImageClick = () => setShowImageCarousel(true);
    const handleCarouselClose = () => setShowImageCarousel(false);

    async function changeMarker(newStatus: string, files?: File[]) {

        setLoading(true);

        let updated = false;

        if (currentMarker?.status && newStatus !== currentMarker?.status) {
            try {
                await updateStatus(newStatus);
                updated = true;
            } catch (error) {
                console.error("An error occurred:", error);
                alert("An error occurred while uploading documents.");
                setLoading(false);
                return StatusCodes.INTERNAL_SERVER_ERROR;
            }
        }

        if (files && files.length > 0) {
            try {
                await uploadFiles(files);
                updated = true;
            } catch (error) {
                console.error("An error occurred:", error);
                alert("An error occurred while uploading documents.");
                setLoading(false);
                return StatusCodes.INTERNAL_SERVER_ERROR;
            }
        }

        if (updated) {

            const userAccessToken = getUserAccessToken();

            if (!userAccessToken) {
                toast.error("Nie jesteś zalogowany lub twój token dostępu wygasł");
            }
            else {
                await fetch(`${getBackendUrl("adminGetIssue")}?id=${currentMarker?.id}&accessToken=${userAccessToken}`)
                    .then(async response => {
                        if (response.status !== 200) {
                            toast.error("Wystąpił błąd. Spróbuj ponownie później.");
                            return;
                        }

                        const updatedMarker = await response.json();
                        onUpdateCurrentMarker({...updatedMarker});
                    })
                    .catch(() => {
                        toast.error("Wystąpił błąd. Spróbuj ponownie później.");
                    });
            }
        }
        else {
            toast.info("Nie dokonano żadnych zmian.");
        }

        setLoading(false);
        return StatusCodes.OK;
    }

    async function updateStatus(newStatus: string) {

        const userAccessToken = getUserAccessToken();

        if (!userAccessToken) {
            toast.error("Nie jesteś zalogowany lub twój token dostępu wygasł");
            return;
        }

        await fetch(`${getBackendUrl("changeStatus")}?id=${currentMarker?.id}&status=${newStatus}&accessToken=${userAccessToken}`)
            .then(async response => {
                if (response.status === 400) {
                    toast.error("NOT FOUND");
                    return;
                }
                toast.success("State updated successfully.");
            })
            .catch(() => {
                toast.error("An error occurred while updating state.");
            });
    }

    async function uploadFiles(files: File[]) {

        const userAccessToken = getUserAccessToken();

        if (!userAccessToken) {
            toast.error("Nie jesteś zalogowany lub twój token dostępu wygasł");
            return;
        }

        let currentDocsCount = -1;
        await fetch(`${getBackendUrl("getDocumentsCount")}?id=${currentMarker?.id}&accessToken=${userAccessToken}`,
            {method: "GET"})
            .then(async response => {
                if (response.status !== 200) {
                    return;
                }
                currentDocsCount = await response.json();
            })
            .catch(error => {
                console.error("An error occurred:", error);
                toast.error("An error occurred while getting documents count.");
            });

        const MAX_DOCS = 6;
        const remainingDocumentsCount = MAX_DOCS - currentDocsCount;

        if (remainingDocumentsCount < files?.length) {
            toast.error(`You can only upload ${remainingDocumentsCount} more documents.`);
            return;
        }

        const formData = new FormData();
        formData.append("id", currentMarker?.id || "");

        for (let i = 0; i < files.length; i++) {
            formData.append("documents", files[i]);
        }

        const uploadResponse = await fetch(`${getBackendUrl("upsertDocs")}?accessToken=${userAccessToken}`, {
            method: "POST",
            body: formData
        });

        if (uploadResponse.status === 200) {
            toast.success("All documents uploaded successfully.");
        } else {
            toast.error("Failed to upload some documents.");
        }
    }

    async function deleteDocument(documentId: string) {
        setLoading(true);

        const userAccessToken = getUserAccessToken();

        if (!userAccessToken) {
            toast.error("Nie jesteś zalogowany lub twój token dostępu wygasł");

            setLoading(false);
            return;
        }

        try {
            await fetch(`${getBackendUrl("deleteDocument")}?id=${currentMarker?.id}&documentId=${documentId}&accessToken=${userAccessToken}`,
                {
                    method: "DELETE"
                }).then(() => {
                    if (currentMarker){
                        const updatedMarker = currentMarker;

                        updatedMarker.documents = Object.values(currentMarker?.documents || {}).filter(doc => doc.id !== documentId);
                        onUpdateCurrentMarker({ ...updatedMarker });
                    }
                });
            toast.success("Document deleted successfully.");
        } catch (error) {
            toast.error("An error occurred while deleting document.");
        }
        setLoading(false);
    }

    const handleDocumentClick = async (document: Document) => {
        const userAccessToken = getUserAccessToken();

        if (!userAccessToken) {
            toast.error("Nie jesteś zalogowany lub twój token dostępu wygasł");
            return;
        }

        const documentURL = `${getBackendUrl("getDocument")}?issueId=${currentMarker?.id}&fileName=${document.fileName}&accessToken=${userAccessToken}`;

        setPreviewedDocumentUrl(await getDocument(documentURL));
    };

    const getDocument = async (url: string) => {
        return await fetch(url)
            .then(response => response.blob())
            .then(blob => {
                return URL.createObjectURL(blob);
            })
            .catch(error => {
                console.error("An error occurred:", error);
                toast.error("An error occurred while fetching document.");
                return null;
            });
    };

    const handleClosePreview = () => {

        setPreviewedDocumentUrl(null);
    };

    return (
        <>
            {
                (currentMarker?.status !== CaseStatus.reported || userContext?.isAdmin())
                    ?
                    <Modal show={show} onHide={handleClose} className={`marker-modal ${showImageCarousel ? "greyed-out" : ""}`}>
                        {loading && <LoaderCar />}
                        <Modal.Header>
                            <Modal.Title>Informacje o zgłoszeniu</Modal.Title>
                            <button type="button" className="btn-close" aria-label="Close" onClick={handleClose} />
                        </Modal.Header>
                        <Modal.Body>
                            {currentMarker?.title && <InfoRow label="Tytuł" value={currentMarker.title} />}
                            {currentMarker?.status && <p><strong>Stan:</strong> {currentMarker.status}</p>}
                            {currentMarker?.description && <InfoRow label="Opis zdarzenia" value={currentMarker.description} />}
                            {currentMarker?.takenActionDescription && <InfoRow label="Podjęte działania" value={currentMarker.takenActionDescription} />}

                            {
                                userContext?.isAdmin() ?
                                    <>
                                        {currentMarker?.email && <p><strong>Email:</strong> {currentMarker.email}</p>}
                                        {currentMarker?.phoneNumber && <p><strong>Numer telefonu:</strong> {currentMarker.phoneNumber}</p>}
                                    </>
                                    :
                                    <></>
                            }

                            {currentMarker?.uploadedImages?.length !== undefined && currentMarker.uploadedImages.length > 0 && (
                                <>
                                    <strong>Zdjęcia:</strong>
                                    <div className="image-grid">
                                        {currentMarker?.uploadedImages.map((image, index) => (
                                            <div key={index} onClick={handleImageClick}>
                                                <img src={image} alt="uploaded" className="image-thumbnail" />
                                            </div>
                                        ))}
                                    </div>
                                </>
                            )}

                            {currentMarker?.documents && Object.values(currentMarker.documents).length > 0 && (
                                <>
                                    <strong>Dokumenty:</strong>
                                    <div className="image-grid">
                                        {Object.values(currentMarker.documents).map((document: Document) => (
                                            <React.Fragment key={document.id}>
                                                <div
                                                    className="clickable-document"
                                                    onClick={() => handleDocumentClick(document)}
                                                >
                                                    {document.fileName}
                                                    <Button
                                                        variant="danger"
                                                        style={{
                                                            position: "absolute",
                                                            top: "0",
                                                            right: "0",
                                                            fontSize: "0.7rem",
                                                            padding: "0.2rem 0.5rem",
                                                            opacity: "0.7"
                                                        }}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            deleteDocument(document.id).then();
                                                        }}
                                                    >
                                                        <MdClose />
                                                    </Button>
                                                </div>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </>
                            )}

                            {
                                userContext?.isAdmin() ?
                                    <>
                                        <hr />
                                        <MarkerManager
                                            initialMarker={currentMarker}
                                            onChangeMarker={changeMarker}
                                        />
                                    </>
                                    :
                                    <></>
                            }

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>Zamknij</Button>
                        </Modal.Footer>

                        {showImageCarousel && (
                            <div className="carousel-overlay">
                                <Carousel>
                                    {currentMarker?.uploadedImages?.map((image, index) => (
                                        <Carousel.Item key={index}>
                                            <img src={image} alt={`uploaded-${index}`} className="carousel-image" />
                                        </Carousel.Item>
                                    ))}
                                </Carousel>
                                <button type="button" className="btn-close image-carousel-close-btn" aria-label="Close" onClick={handleCarouselClose} />
                            </div>
                        )}

                        {previewedDocumentUrl && (
                            <DocumentPreview
                                url={previewedDocumentUrl}
                                onClose={handleClosePreview}
                            />
                        )}
                    </Modal>
                    :
                    <>Zgłoszenie jest rozpatrywane</>
            }

        </>
    );
};

export default MarkerInfoModal;