import React, {FC, useEffect} from "react";
import "../../styles/documentPreview.css";
import Button from "react-bootstrap/Button";
import { MdClose } from "react-icons/md";

interface Props {
    url: string;
    onClose: () => void;
}

const DocumentPreview: FC<Props> = ({url, onClose}) => {

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const target = event.target as Element;
            if (target && !target.closest(".document-preview-overlay iframe")) {
                onClose();
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [onClose]);

    const isPdf = url.toLowerCase().endsWith(".pdf");

    return (
        <div className="document-preview document-preview-overlay">
            <>
                {isPdf ?
                    <iframe
                        src={url} title="Document Preview"
                    />
                    :
                    <p>Your browser does not support iframes.</p>
                }
                <Button
                    style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        zIndex: "999",
                    }}
                    variant="secondary" onClick={onClose}>
                    <MdClose />
                </Button>
            </>
        </div>
    );
};

export default DocumentPreview;
