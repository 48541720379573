import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import DOMPurify from "dompurify";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import "../../styles/MarkerModal.css";
import { StatusCodes } from "http-status-codes";
import { CaseStatus } from "../../models/CaseStatus";
import { UserMarker } from "../../models/UserMarker";
import ImageUploader from "../form-components/ImageUploader";
import MarkerForm, {directPolishPhonePrefix} from "../form-components/MarkerForm";
import {getBackendUrl, isValidEmail, isValidPhoneNumber} from "../../scripts/utils";
import { ReCaptcha } from "../form-components/ReCaptcha";
import {Document} from "../../models/Document";
import LoaderCar from "../LoaderCar";

interface MarkerModalProps {
    show: boolean;
    onClose: () => void;
    currentMarker: UserMarker | undefined;
    onAddMarker: (marker: UserMarker) => void;
}

async function sendImageForSanitization(fileBase64Url: string): Promise<string> {

    const response = await fetch(getBackendUrl("sanitizeImage"), {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ file: fileBase64Url }),
    });

    if (!response.ok) {
        throw new Error(`Server error: ${response.status}`);
    }

    const responseData = await response.json();
    return responseData.imageAsBase64Url;
}

async function sanitizeImages(uploadedImages: string[]): Promise<string[]> {
    for (let i = 0; i < uploadedImages.length; i++) {
        uploadedImages[i] = await sendImageForSanitization(uploadedImages[i]);
    }

    return uploadedImages;
}

const MarkerModal: React.FC<MarkerModalProps> = ({ show, onClose, currentMarker, onAddMarker }) => {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [takenActionDescription, setTakenActionDescription] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState(directPolishPhonePrefix);
    const [uploadedImages, setUploadedImages] = useState<string[]>([]);
    const [, setDocuments] = useState<Document[]>([]);
    const [isReCaptchaValid, setIsReCaptchaValid] = useState<boolean>(false);

    const [attemptedSubmit, setAttemptedSubmit] = useState(false);
    const [imageError, setImageError] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (currentMarker) {
            setTitle(currentMarker.title || "");
            setDescription(currentMarker.description || "");
            setTakenActionDescription(currentMarker.takenActionDescription || "");
            setEmail(currentMarker.email || "");
            setPhoneNumber(currentMarker.phoneNumber || directPolishPhonePrefix);
            setUploadedImages(currentMarker.uploadedImages || []);
            setDocuments(currentMarker.documents || []);

            setAttemptedSubmit(false);
            setImageError("");
        }
    }, [currentMarker]);

    // Safeguard against accidentally closing the page after form filling.
    useEffect(() => {
        const warnOnPageExit = (e: BeforeUnloadEvent) => {
            if (title || description || takenActionDescription || email || phoneNumber || uploadedImages.length > 0) {
                e.preventDefault();
                e.returnValue = "You have unsaved changes! Are you sure you want to leave?";
            }
        };

        window.addEventListener("beforeunload", warnOnPageExit);
        return () => {
            window.removeEventListener("beforeunload", warnOnPageExit);
        };
    }, [title, description, email, phoneNumber, uploadedImages]);

    const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files || [];
        const MAX_SIZE: number = 10 * 1024 * 1024; // 10MB
        const MAX_IMAGES_COUNT = 3;

        if (files.length > MAX_IMAGES_COUNT) {
            setImageError(`Możesz przesłać maksymalnie ${MAX_IMAGES_COUNT} zdjęć.`);
            return;
        }

        const newImages:  string[] = [];
        let readCount = 0;

        function handleFileRead (file: File) {
            const reader = new FileReader();
            reader.onload = () => {
                newImages.push(reader.result as string);
                readCount++;

                if (readCount === files.length) {
                    setImageError(null);
                    setUploadedImages([...uploadedImages, ...newImages]);
                }
            };
            reader.readAsDataURL(file);
        }

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            if (file.size > MAX_SIZE) {
                setImageError("Plik większy niż 2MB. Prześlij mniejszy plik");
                return;
            }

            if (file.type === "image/jpg" || file.type === "image/jpeg" || file.type === "image/png") {
                handleFileRead(file);
                setImageError(null);
            }
            else {
                setImageError("Niepoprawny format pliku. Dozowolone formaty to: jpg, jpeg, png.");
                return;
            }
        }
    };

    const handleCaptchaChange = async (isValid: boolean) => {
        setIsReCaptchaValid(isValid);
    };

    const handleImageRemove = (index: number) => {
        const imagesCopy = [...uploadedImages];
        imagesCopy.splice(index, 1);
        setUploadedImages(imagesCopy);
    };

    const handleModalSave = async () => {
        setLoading(true);
        setAttemptedSubmit(true);

        let isValid = true;

        if (!isReCaptchaValid) {
            isValid = false;
        }

        if (title.trim() === "" || imageError) {
            isValid = false;
        }

        if (!isValidEmail(email)) {
            isValid = false;
        }

        if (phoneNumber.trim() !== "" && !isValidPhoneNumber(phoneNumber)) {
            isValid = false;
        }

        if (!isValid) {
            setLoading(false);
            return;
        }

        setAttemptedSubmit(false);

        if (currentMarker) {
            const updatedMarker: UserMarker = {...currentMarker};

            updatedMarker.title = DOMPurify.sanitize(title);
            updatedMarker.description = DOMPurify.sanitize(description);
            updatedMarker.takenActionDescription = DOMPurify.sanitize(takenActionDescription);
            updatedMarker.email = DOMPurify.sanitize(email);
            updatedMarker.phoneNumber = phoneNumber === directPolishPhonePrefix ? "" : phoneNumber;
            currentMarker.status = CaseStatus.inReview;

            try {
                await sanitizeImages(uploadedImages)
                    .then(sanitizedImages => {
                        updatedMarker.uploadedImages = sanitizedImages;
                    })
                    .catch(error => {
                        throw new Error(error);
                    });

                currentMarker = updatedMarker;

                await saveIssueToDb(currentMarker)
                    .then(response => {
                        if (response.status === StatusCodes.OK) {
                            onAddMarker(updatedMarker);
                        }
                        else {
                            throw new Error(`Server error: ${response.status}`);
                        }
                    })
                    .catch(error => {
                        throw new Error(error);
                    });

                toast.success("Zgłoszenie wysłane. Wkrótce zostanie rozpatrzone.");
            }
            catch (error) {
                toast.error("Wystąpił błąd podczas zapisywania zgłoszenia. Spróbuj ponownie później.");
            }
            finally {
                setLoading(false);
                onClose();
            }
        }
        else {
            setLoading(false);
            onClose();
        }
    };

    const handleClose = () => {
        onClose();
    };
    async function saveIssueToDb (marker: UserMarker) {
        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({"issue": marker})
        };

        return await fetch(getBackendUrl("addIssue"), requestOptions)
            .then(response => {
                return response;
            })
            .catch(error => {
                return new Response(error, {status: StatusCodes.SERVICE_UNAVAILABLE});
            });
    }

    return (
        <Modal show={show} onHide={handleClose} className="marker-modal">
            {loading && <LoaderCar />}
            <Modal.Header>
                <Modal.Title style={{ margin: 0 }}>Dodaj zgłoszenie</Modal.Title>
                <button type="button" className="btn-close" aria-label="Close" onClick={handleClose} />
            </Modal.Header>
            <Modal.Body>
                <MarkerForm
                    title={title} setTitle={setTitle}
                    description={description} setDescription={setDescription}
                    takenActionDescription={takenActionDescription}
                    setTakenActionDescription={setTakenActionDescription}
                    email={email} setEmail={setEmail}
                    phoneNumber={phoneNumber} setPhoneNumber={setPhoneNumber}
                    attemptedSubmit={attemptedSubmit}
                />
                <ImageUploader
                    uploadedImages={uploadedImages}
                    handleImageChange={handleImageChange}
                    handleImageRemove={handleImageRemove}
                    imageError={imageError}
                />
                <ReCaptcha onCaptchaChange={handleCaptchaChange} error={!isReCaptchaValid && attemptedSubmit} />

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Przerwij</Button>
                <Button variant="primary" onClick={handleModalSave}>Zapisz</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default MarkerModal;