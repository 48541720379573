import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

const CookieConsentBanner = () => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        const hasAcceptedCookies = Cookies.get("hasAcceptedCookies");

        if (!hasAcceptedCookies) {
            setShow(true);
        }
    }, []);

    const handleAccept = () => {
        Cookies.set("hasAcceptedCookies", "true", { expires: 365 });

        setShow(false);
    };

    return (
        <div>
            {show && (
                <Alert style={{ backgroundColor: "#343a40", color: "#ffffff" }} className="fixed-bottom mb-0 rounded-0">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex">
                            <span style={{ color: "#ffffff" }}>Używamy tylko niezbędnych ciasteczek.</span>
                        </div>
                        <div>
                            <Button variant="secondary" onClick={handleAccept}>
                                Zaakceptuj
                            </Button>
                        </div>
                    </div>
                </Alert>
            )}
        </div>
    );
};

export default CookieConsentBanner;
