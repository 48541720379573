import React, {useContext, useState} from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import {toast} from "react-toastify";

import Login from "./modals/Login";
import {signOutUser} from "../scripts/authorizeUser";
import UserContext from "../contexts/UserContext";

interface Props {
    isAdminView: boolean;
}

const MyNavbar: React.FC<Props> = ({isAdminView}) => {
    const [showLoginModal, setShowLoginModal] = useState(false);
    const userContext = useContext(UserContext);

    const toggleLoginModal = () => {
        setShowLoginModal(!showLoginModal);
    };

    const handleLogout = async () => {
        try {
            userContext?.setUser(null);
            localStorage.removeItem("user");
            const response = await signOutUser();
            if (response.status !== 200){
                toast.error("Wystąpił błąd podczas wylogowywania. Spróbuj ponownie później. Skontatuj się z administratorem, jeśli problem będzie się powtarzał.");
                return;
            }

            toast.success("Wylogowano pomyślnie!");
        }
        catch (err) {
            toast.error("Wystąpił błąd podczas wylogowywania. Spróbuj ponownie później. Skontatuj się z administratorem, jeśli problem będzie się powtarzał.");
        }
    };

    return (
        <>
            <Navbar bg="dark" variant="dark" expand="lg" fixed="top">
                <Container>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />

                        <Navbar.Collapse id="basic-navbar-nav">

                            {isAdminView
                                ?
                                <>
                                    <Nav className="me-auto">
                                        {!userContext?.user ? (
                                            <Nav.Link className="nav-link" onClick={toggleLoginModal}>Login</Nav.Link>
                                        ) : (
                                            <Nav.Link className="nav-link" onClick={handleLogout}>Logout</Nav.Link>
                                        )}
                                    </Nav>

                                    {userContext?.user && (
                                        <Nav className="ml-auto">
                                            <Nav.Item className="text-white">
                                                Zalogowany jako: <i>{userContext.user.email}</i>
                                            </Nav.Item>
                                        </Nav>
                                    )}
                                </>
                                :
                                <Nav className="me-auto">
                                    <Nav.Link active={true} style={{cursor: 'default'}} className="nav-link">
                                        Dodaj zgłoszenie klikając na mapę
                                    </Nav.Link>
                                </Nav>
                            }
                        </Navbar.Collapse>

                </Container>
            </Navbar>
            <Login
                show={showLoginModal}
                onClose={toggleLoginModal}
            />
        </>
    );
};

export default MyNavbar;
