import React, { useContext, useState } from "react";
import { Button, Form } from "react-bootstrap";
import UserContext from "../../contexts/UserContext";
import { StatusCodes } from "http-status-codes";
import {signInUser} from "../../scripts/authorizeUser";
import { toast } from "react-toastify";
import "../../styles/MarkerModal.css";
import Modal from "react-bootstrap/Modal";

interface LoginProps {
    show: boolean;
    onClose: () => void;
}

function Login({ show, onClose }: LoginProps) {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [loginError, setLoginError] = useState<string | undefined>(undefined);
    const [passwordError, setPasswordError] = useState<string | undefined>(undefined);
    const userContext = useContext(UserContext);
    const [attemptedSubmit, setAttemptedSubmit] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    function isEmailValid(email: string): boolean {
        const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return pattern.test(email);
    }

    function isPasswordValid(password: string): boolean {
        return password.length >= 8;
    }

    async function handleLogin(e: React.FormEvent) {
        e.preventDefault();
        setLoading(true);

        setAttemptedSubmit(true);
        setLoginError(undefined);
        setPasswordError(undefined);

        if (!isEmailValid(email)) {
            setLoginError("Niepoprawny format maila");
            setLoading(false);
            return;
        }

        if (!isPasswordValid(password)) {
            setPasswordError("Hasło musi mieć co najmniej 8 znaków");
            setLoading(false);
            return;
        }

        if(userContext){
            const { setUser } = userContext;

            try {
                await signInUser(email, password)
                    .then(async response => {
                        return await response.json();
                    })
                    .then(user => {
                        setUser(user);
                        localStorage.setItem("user", JSON.stringify(user));
                        onClose();
                        toast.success("Zalogowano pomyślnie");
                    })
                    .catch((error) => {
                        switch (error.status) {
                            case StatusCodes.BAD_REQUEST || StatusCodes.NOT_FOUND:
                                setLoginError("Niepoprawny email");
                                break;
                            case StatusCodes.UNAUTHORIZED:
                                setPasswordError("Niepoprawne hasło");
                                break;
                            default:
                                toast.error("Wystąpił błąd podczas logowania");
                                break;
                        }

                        toast.error("Nie udało się zalogować.");
                    });
            } catch (_) {
                toast.error("Wystąpił błąd podczas logowania.");
            }
        }

        setLoading(false);
    }

    const handleModalCancel = () => {
        setPassword("");
        setLoginError(undefined);
        setPasswordError(undefined);
        setAttemptedSubmit(false);
        setLoading(false);

        onClose();
    };

    return (
        <Modal show={show}  onHide={handleModalCancel} className="marker-modal">

            {loading && (
                <div className={"loader"}/>
            )}

            <Modal.Header>
                <Modal.Title style={{margin: 0}}>Login</Modal.Title>
                <button type="button" className="btn-close" aria-label="Close" onClick={handleModalCancel}/>
            </Modal.Header>

            <Modal.Body>
                <Form onSubmit={handleLogin}>
                    <Form.Group className="mb-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            isInvalid={attemptedSubmit && (!isEmailValid(email) || loginError !== undefined)}
                            autoComplete={"email"}
                        />
                        <Form.Control.Feedback type='invalid'>
                            {loginError || "Email niepoprawny"}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Hasło</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="Hasło"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            isInvalid={attemptedSubmit && (!isPasswordValid(password) || passwordError !== undefined)}
                            autoComplete={"current-password"}
                        />
                        <Form.Control.Feedback type='invalid'>
                            {passwordError || "Hasło niepoprawne"}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        Zaloguj się
                    </Button>
                </Form>

            </Modal.Body>

            <Modal.Footer/>
        </Modal>
    );
}

export default Login;
