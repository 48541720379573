import React, { Component, ReactNode } from "react";
import emailjs from "@emailjs/browser";
import {toast} from "react-toastify";
import {ErrorInfo} from "./common/ErrorInfo";

export const sendEmail = (errorMessage: string): void => {
    const templateParams = { error: errorMessage };
    emailjs.send(
        "service_1r3zycp",
        "template_xbsf1bg",
        templateParams,
        "5xjr1eM1_aDB5qdPO"
    ).then();
};

interface State {
    connectionError: boolean;
    connectionErrorMessage: string;
    hasError?: boolean;
}

interface Props {
    children: ReactNode;
}

export default class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            connectionError: false,
            connectionErrorMessage: "Wystąpił błąd połączenia z serwerem. Spróbuj ponownie później."
        };
    }

    static getDerivedStateFromError(): Partial<State> {
        return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        const errorMessage = error.message || "nieznany błąd";
        sendEmail(errorMessage);
        toast.error("Wystąpił błąd. Spróbuj ponownie później.");
        console.log("Caught by ErrorBoundary", error, errorInfo);
    }

    render(): ReactNode {
        if (this.state.hasError) {
            return (
                <div style={{ textAlign: "center" }}>
                    <h3>{`${this.state.connectionErrorMessage}`}</h3>
                    <ErrorInfo/>
                </div>
            );
        }

        return this.props.children;
    }
}
