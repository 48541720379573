import React from 'react';
import { AsyncTypeahead, Token } from 'react-bootstrap-typeahead';
import { Option } from 'react-bootstrap-typeahead/types/types';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { GeocodeData } from './modals/GeocodeData';
import { getBackendUrl } from '../scripts/utils';

import '../styles/mapSearchBar.css';
import {PlaceSearchRequest} from "../models/PlaceSearchRequest";
import {PlaceSuggestion} from "../models/PlaceSuggestion";

type propTypes = {
    onSearchLocation: (geocodeData: GeocodeData) => void;
};

type State = {
    isLoading: boolean;
    options: Option[];
    autoFocus: boolean;
};

export default class MapSearchBar extends React.Component<propTypes, State> {
    constructor(props: propTypes) {
        super(props);
        this.state = {
            isLoading: false,
            options: [],
            autoFocus: true,
        };
    }

    handlePlaceSearchSubmit = async (searchTerm: string) => {
        try {

            const placeSearchRequest: PlaceSearchRequest = {
                input: searchTerm,
                locationBias: {
                    circle: {
                        center: {
                            latitude: 54.39,
                            longitude: 18.64,
                        },
                        radius: 50,
                    },
                },
            };

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(placeSearchRequest),
            };

            const response = await fetch(`${getBackendUrl('getPlaces')}`,
                requestOptions);

            if (!response.ok) {
                return [];
            }

            const data = await response.json();

            let results = data.suggestions ?? [];

            results = results.filter((placeSuggestion: PlaceSuggestion) => {
                const formattedAddress = placeSuggestion.placePrediction.text.text.toLowerCase();
                return formattedAddress.includes('gdańsk') || formattedAddress.includes('sopot') || formattedAddress.includes('gdynia');
            });

            return results;
        } catch (error) {
            return [];
        }
    };

    getGeocodingForPlace = async (searchTerm: string) => {
        try {
            const response = await fetch(`${getBackendUrl('getGeocoding')}?searchTerm=${searchTerm}`);

            if (!response.ok) {
                return [];
            }

            const data = await response.json();

            const results = data.results ?? [];

            return results[0];
        } catch (error) {
            return [];
        }
    };

    handleUserLocationChoice = async (selectedValue: Option[]) => {
        const formattedAddress = selectedValue[0]?.search;

        if (!formattedAddress) return;

        const geocoding = await this.getGeocodingForPlace(formattedAddress);

        if (geocoding) {
            this.props.onSearchLocation(geocoding);
        }

        this.setState({ autoFocus: false });
    };

    handleSearch = async (query: string) => {
        this.setState({ isLoading: true });

        try {

            const places = await this.handlePlaceSearchSubmit(query);

            const options: Option[] = places.map((placeSuggestion: PlaceSuggestion) => (
                { search: placeSuggestion.placePrediction.text.text }
            ));

            this.setState({
                isLoading: false,
                options: options,
                autoFocus: true,
            });
        } catch (error) {
            this.setState({ isLoading: false });
        }
    };

    render() {
        const { isLoading, options} = this.state;
        return (
            <div>
                <AsyncTypeahead
                    autoFocus={this.state.autoFocus}
                    className="search-bar"
                    isLoading={isLoading}
                    options={options}
                    id="typeahead"
                    delay={1000}
                    emptyLabel={<div style={{color: 'lightgray'}}>Nie znaleziono w Trójmieście</div>}
                    ignoreDiacritics
                    labelKey={option => `${option.search}`}
                    minLength={3}
                    onSearch={this.handleSearch}
                    placeholder="Wyszukaj ulicę na mapie"
                    promptText={<div style={{color: 'lightgray'}}>podaj ulicę i miasto lub kod</div>}
                    searchText={<div style={{color: 'lightgray'}}>szukam...</div>}
                    defaultOpen={this.state.autoFocus}
                    useCache={true}
                    onChange={this.handleUserLocationChoice}
                    renderMenuItemChildren={(selectedItem: Option) => (
                        <Token readOnly option={selectedItem} tabIndex={5}>
                            {selectedItem.search}
                        </Token>
                    )}
                />
            </div>
        );
    }
}
