import React, {ChangeEvent, useRef} from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

interface ImageUploaderProps {
    imageError: string | null;
    uploadedImages: string[];
    handleImageChange: (event: ChangeEvent<HTMLInputElement>) => void;
    handleImageRemove: (index: number) => void;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
    imageError,
    uploadedImages,
    handleImageChange,
    handleImageRemove
}) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const removeFile = (index: number) => {
        if (fileInputRef.current) {
            fileInputRef.current.value = "";
        }

        handleImageRemove(index);
    };

    return (
        <Form.Group className={"form-group"}>
            <div>
                <Form.Label>Prześlij zdjęcia:</Form.Label>
                <div>
                    <input
                        type="file"
                        multiple
                        accept=".jpg,jpeg,.png"
                        onChange={handleImageChange}
                        style={{display: "none"}}
                        ref={fileInputRef}
                    />
                    <Button variant="primary" onClick={() => fileInputRef.current?.click()}>Prześlij zdjęcie</Button>
                </div>
                {imageError && <div className='invalid-feedback d-block'>{imageError}</div>}
                {uploadedImages.length > 0 && (
                    <div className={"images-grid"}>
                        {uploadedImages.length > 0 &&
                            uploadedImages.map((image, index) => (
                                <div key={index}
                                    style={{position: "relative"}}>
                                    <Button
                                        variant="danger"
                                        style={{
                                            position: "absolute",
                                            top: "0",
                                            right: "0",
                                            fontSize: "0.7rem",
                                            padding: "0.2rem 0.5rem"
                                        }}
                                        onClick={() => removeFile(index)}
                                    >
                                        X
                                    </Button>
                                    <img src={image} style={{height: "100px", width: "auto"}} alt={"uploaded"}/>
                                </div>
                            ))}
                    </div>
                )}
            </div>
        </Form.Group>
    );
};

export default ImageUploader;
