import {directPolishPhonePrefix} from "../components/form-components/MarkerForm";

const development: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

function isDev(): boolean
{
    return development;
}

export const isValidEmail = (email: string) => {
    if(email === "")
    {
        return true;
    }

    return /\S+@\S+\.\S+/.test(email);
}

export const isValidPhoneNumber = (number: string) => {
    if(number.replace(/\s/g, "") === directPolishPhonePrefix)
    {
        return true;
    }

    return /^\+\d{2}\s?\d{3}\s?\d{3}\s?\d{3}$/.test(number.replace(/\s/g, ""));
}

export const getBackendUrl = (endpoint: string) => {
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
    if(isDev()){
        return `${backendUrl}/${endpoint}`;
    }
    else
    {
        return `https://${endpoint}${backendUrl}`;
    }
}
