import React from 'react';

import '../styles/loaderCar.css';

const LoaderCar = () => {
    return (
        <div className="loader">

            <div>
                <svg className="center" width="500" height="500" viewBox="0 0 1154 1154" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                    <g id="circle">
                        <circle id="grass" cx="577" cy="577" r="531" transform="rotate(5.16785 577 577)"
                                fill="url(#paint0_linear_2_34)"/>
                        <g id="car">
                            <path id="body-shadow"
                                  d="M816.254 777.847C810.202 792.468 804.307 820.455 802.117 832.621C802.226 849.738 890.515 888.227 903.104 879.062C913.176 871.73 929.02 843.541 935.684 830.363C938.114 812.12 946.489 791.988 950.373 784.203L982.346 713.804C983.5 710.5 984.721 706.019 984.721 706.019L993.998 685.921C1002.17 675.762 1012.11 654.228 1011.39 647.105C1011.19 645.114 1018.96 634.057 1017.8 630.209C1014.49 619.277 1005.65 615.779 1002.01 613.865C984.173 597.796 957.781 586.31 932.05 582.444C923.702 577.894 910.019 581.504 907.203 584.21L901.515 599.173C895.95 602.176 888.078 625.013 884.839 636.056L874.402 657.535C874.402 657.535 873.794 659 871.696 663.774C863.615 682.161 845.819 722.776 839.281 738.148C832.743 753.52 821.206 771.019 816.254 777.847Z"
                                  fill="#B5D2B2"/>
                            <path id="wheels-font"
                                  d="M906.051 598.521L907.236 611.094L999.258 658.93L1004.61 683.191C1003.41 686.455 996.891 686.154 993.781 685.595L988.597 670.775L895.411 624.496L891.577 599.582C894.79 595.56 902.565 597.199 906.051 598.521Z"
                                  fill="#453636"/>
                            <path id="wheels-back"
                                  d="M835.023 759.185L836.208 771.757L928.23 819.594L933.585 843.854C932.384 847.118 925.863 846.817 922.753 846.258L917.568 831.438L824.383 785.159L820.548 760.245C823.762 756.223 831.537 757.862 835.023 759.185Z"
                                  fill="#453636"/>
                            <path id="body"
                                  d="M816.835 777.52C810.783 792.141 804.888 820.127 802.698 832.293C802.807 849.41 891.096 887.899 903.685 878.734C913.757 871.402 929.601 843.213 936.265 830.035C938.695 811.792 947.07 791.661 950.954 783.876L982.927 713.477L995.682 720.932C1000.63 712.627 990.823 707.312 985.302 705.692L994.579 685.594C1002.75 675.434 1012.69 653.9 1011.97 646.778C1011.77 644.787 1019.55 633.729 1018.38 629.882C1015.07 618.949 1006.23 615.452 1002.59 613.537C984.755 597.469 958.362 585.983 932.632 582.116C924.283 577.566 910.6 581.177 907.784 583.882L902.096 598.845C896.531 601.849 888.659 624.686 885.42 635.729L874.983 657.207C867.43 652.745 860.517 651.575 858.142 659.36L872.277 663.447C864.196 681.833 846.4 722.449 839.862 737.821C833.324 753.192 821.787 770.692 816.835 777.52Z"
                                  fill="#EB2F2F"/>
                            <path id="body-shadow_2"
                                  d="M816.835 777.52C810.783 792.141 804.888 820.127 802.698 832.293C802.807 849.41 891.096 887.899 903.685 878.734C913.757 871.402 929.601 843.213 936.265 830.035C938.695 811.792 947.07 791.661 950.954 783.876L982.927 713.477L995.682 720.932C1000.63 712.627 990.823 707.312 985.302 705.692L994.579 685.593C1002.75 675.434 1012.69 653.9 1011.97 646.778C1011.77 644.787 1019.55 633.729 1018.38 629.882C1015.07 618.949 1006.23 615.452 1002.59 613.537C993.649 607.969 985.417 609.616 992.372 642.083C997.937 668.057 952.283 699.506 928.761 711.984C928.686 732.266 921.502 775.47 893.364 786.026C858.191 799.221 843.948 746.324 832.461 786.3C823.272 818.28 818.215 793.772 816.835 777.52Z"
                                  fill="#DC2929"/>
                            <g id="windows-group">
                                <g id="windows">
                                    <path
                                        d="M863.163 736.386C852.635 754.165 831.544 790.021 831.412 791.214C830.617 780.259 837.781 759.557 841.463 750.576L877.025 670.514L872.827 708.282C872.209 713.847 866.127 729.337 863.163 736.386Z"
                                        fill="#D2F8FB"/>
                                    <path
                                        d="M942.672 785.973C937.768 797.502 922.222 820.593 915.062 830.697C920.934 814.077 934.114 776.973 939.857 761.513C945.6 746.053 965.92 723.157 975.363 713.642C966.509 732.949 947.575 774.445 942.672 785.973Z"
                                        fill="#D2F8FB"/>
                                    <path
                                        d="M829.589 807.613L842.013 786.355C874.313 812.584 912.081 816.782 912.081 816.782C912.081 816.782 905.829 840.437 902.914 844.942C899.269 850.574 903.686 851.568 882.208 850.187C860.729 848.805 839.968 831.908 831.134 825.392C824.067 820.18 827.159 811.367 829.589 807.613Z"
                                        fill="#D2F8FB"/>
                                </g>
                                <g id="windows-shadow">
                                    <path
                                        d="M942.672 785.973C937.768 797.502 922.222 820.593 915.062 830.697C920.934 814.077 934.114 776.973 939.857 761.513C945.6 746.053 947.752 767.421 947.752 767.421C947.752 767.421 947.575 774.445 942.672 785.973Z"
                                        fill="#B0E6EA"/>
                                    <path
                                        d="M829.589 807.613C829.589 807.613 830.196 811.202 864.706 817.553C899.215 823.905 912.081 816.782 912.081 816.782C912.081 816.782 905.829 840.437 902.914 844.942C899.269 850.574 903.686 851.568 882.208 850.187C860.729 848.805 839.968 831.908 831.134 825.392C824.067 820.18 827.159 811.367 829.589 807.613Z"
                                        fill="#B0E6EA"/>
                                </g>
                                <path id="window"
                                      d="M909.051 653.951C876.61 642.567 880.834 699.613 880.834 699.613C924.565 704.474 950.846 730.537 950.846 730.537C950.846 730.537 993.53 695.035 965.591 679.353C940.303 662.456 929.872 661.257 909.051 653.951Z"
                                      fill="#CEF3F5"/>
                                <path id="window-shadow"
                                      d="M909.051 653.951C876.61 642.567 914.847 687.799 914.847 687.799C958.578 692.659 950.846 730.537 950.846 730.537C950.846 730.537 993.53 695.035 965.591 679.353C940.303 662.456 929.872 661.257 909.051 653.951Z"
                                      fill="#BCE6E9"/>
                            </g>
                            <g id="back-lights">
                                <ellipse id="Ellipse 3" cx="893.671" cy="873.788" rx="7.99171" ry="2.6639"
                                         transform="rotate(6.53196 893.671 873.788)" fill="#D9D9D9"/>
                                <ellipse id="Ellipse 4" cx="813.211" cy="836.103" rx="7.99171" ry="2.6639"
                                         transform="rotate(45.6701 813.211 836.103)" fill="#D9D9D9"/>
                            </g>
                            <g id="front-lights">
                                <ellipse id="Ellipse 1" cx="920.404" cy="587.602" rx="10.5" ry="3.5"
                                         transform="rotate(-3.85244 920.404 587.602)" fill="#D9D9D9"/>
                                <ellipse id="Ellipse 2" cx="1005.37" cy="624.964" rx="10.5" ry="3.5"
                                         transform="rotate(42.7519 1005.37 624.964)" fill="#D9D9D9"/>
                            </g>
                        </g>
                        <g id="smoke">
                            <path
                                d="M613.024 850.356C565.66 818.962 528.523 879.865 561.764 911.989C590.973 877.681 619.472 893.78 630.071 906.118C581.18 880.9 558.045 923.422 568.619 952.773C579.584 975.854 612.545 988.876 637.836 959.033C667.136 963.691 684.98 937.358 690.24 923.609L698.207 924.329C703.846 978.558 779.018 941.177 735.272 903.082C746.933 902.931 750.169 912.294 752.073 917.152C770 918.773 783.209 900.389 776.41 881.198C813.525 870.498 767.919 825.206 756.577 867.355C748.427 864.209 745.349 863.83 736.162 871.032C726.058 849.534 691.488 840.885 670.481 875.634C669.061 860.244 684.97 850.504 693.102 847.557C685.384 799.668 610.334 807.941 618.057 866.876C614.298 864.026 612.259 858.821 613.024 850.356Z"
                                fill="#FDF9F9"/>
                            <path
                                d="M736.87 835.451C713.728 813.778 740.4 792.909 759.083 806.333C777.468 819.543 758.198 849.429 736.87 835.451Z"
                                fill="#FDF9F9"/>
                            <path
                                d="M814.926 866.106C801.135 867.369 801.677 885.882 810.23 890.281C817.229 893.882 826.705 889.548 828.559 881.898C830.479 873.98 823.04 865.363 814.926 866.106Z"
                                fill="#FDF9F9"/>
                            <path
                                d="M541.671 923.225C534.624 900.661 498.488 892.318 482.635 909.853C472.913 920.607 470.863 933 476.315 946.433C483.749 964.75 505.4 973.608 523.311 965.242C539.536 957.665 547.008 940.318 541.671 923.225Z"
                                fill="#FDF9F9"/>
                        </g>
                        <path id="Vector 10"
                              d="M815.893 705.54C800.267 719.188 760.432 748.118 726.104 754.653M840.423 695.208C806.765 739.858 733.21 771.561 687.003 776.218M832.829 718.117C830.204 719.386 826.994 727.128 806.765 739.858M542.47 792.265C667.46 803.569 742.747 779.352 792.962 748.148"
                              stroke="#ADE7A7" strokeWidth="3"/>
                    </g>
                    <defs>
                        <linearGradient id="paint0_linear_2_34" x1="682.546" y1="960.369" x2="787.426" y2="-103.001"
                                        gradientUnits="userSpaceOnUse">
                            <stop stopColor="#CCF7C8"/>
                            <stop offset="1" stopColor="#CCF7C8" stopOpacity="0"/>
                        </linearGradient>
                    </defs>
                </svg>
                <svg className="moving-car-blue" viewBox="0 0 267 343" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="blue-car">
                        <path id="Vector 1"
                              d="M64.0031 250.298C63.8766 266.121 68.9094 294.276 71.4417 306.375C77.9635 322.201 174.243 324.766 182.475 311.548C189.062 300.973 193.177 268.9 194.411 254.185C189.821 236.362 190.034 214.559 190.714 205.885L193.948 128.634L208.568 130.76C210.04 121.206 198.956 119.957 193.23 120.526L194.291 98.4155C198.056 85.9326 199.194 62.2428 195.857 55.9091C194.924 54.1386 197.982 40.9733 195.458 37.8442C188.287 28.9519 178.786 29.0234 174.69 28.6157C152.131 20.4092 123.357 19.6609 98.0546 25.7278C88.609 24.6411 77.2795 33.12 75.6837 36.6842L76.0234 52.6884C71.9904 57.5602 73.2595 81.6822 74.3981 93.1341L72.7794 116.959C64.104 115.656 57.2565 117.164 57.9754 125.271L72.6112 123.758C72.0168 143.833 70.754 188.158 70.4586 204.86C70.1633 221.562 66.0319 242.111 64.0031 250.298Z"
                              fill="#2F58EB"/>
                        <path id="Vector 6"
                              d="M64.0031 250.298C63.8766 266.121 68.9094 294.276 71.4417 306.375C77.9635 322.201 174.243 324.766 182.475 311.548C189.062 300.973 193.177 268.9 194.411 254.185C189.821 236.362 190.034 214.559 190.714 205.885L193.948 128.634L208.568 130.76C210.04 121.206 198.956 119.957 193.23 120.526L194.291 98.4155C198.056 85.9327 199.194 62.2428 195.857 55.9091C194.924 54.1386 197.982 40.9734 195.458 37.8442C188.287 28.9519 178.786 29.0234 174.69 28.6157C164.314 26.8073 157.301 31.4216 175.926 58.9096C190.827 80.8999 160.301 127.176 143.176 147.566C150.715 166.396 160.26 209.14 138.135 229.48C110.479 254.904 77.4354 211.211 81.7812 252.576C85.2578 285.669 71.3777 264.846 64.0031 250.298Z"
                              fill="#293BDC"/>
                        <g id="Vector 3">
                            <path
                                d="M91.5206 194.791C88.4291 215.221 82.3272 256.37 82.6517 257.525C77.8062 247.668 76.6826 225.79 76.7265 216.084L79.6638 128.529L89.9378 165.113C91.4519 170.505 91.6239 187.145 91.5206 194.791Z"
                                fill="#D2F8FB"/>
                            <path
                                d="M183.823 210.936C183.602 223.463 177.851 250.698 175.004 262.751C174.214 245.142 172.514 205.803 172.039 189.317C171.565 172.832 181.814 143.986 186.998 131.624C186.032 152.842 184.045 198.41 183.823 210.936Z"
                                fill="#D2F8FB"/>
                            <path
                                d="M87.1127 273.411L90.657 249.045C130.437 261.244 167.022 250.97 167.022 250.97C167.022 250.97 170.099 275.242 169.086 280.512C167.82 287.1 172.287 286.365 151.858 293.14C131.429 299.916 105.847 292.039 95.2138 289.313C86.7073 287.131 86.2687 277.802 87.1127 273.411Z"
                                fill="#D2F8FB"/>
                        </g>
                        <g id="Vector 5">
                            <path
                                d="M183.823 210.936C183.602 223.463 177.851 250.698 175.004 262.751C174.214 245.142 172.514 205.803 172.039 189.317C171.565 172.832 181.575 191.833 181.575 191.833C181.575 191.833 184.045 198.41 183.823 210.936Z"
                                fill="#B0E6EA"/>
                            <path
                                d="M87.1127 273.411C87.1127 273.411 89.0218 276.51 123.394 269.454C157.767 262.398 167.022 250.97 167.022 250.97C167.022 250.97 170.099 275.242 169.086 280.512C167.82 287.1 172.287 286.365 151.858 293.14C131.429 299.916 105.847 292.039 95.2138 289.313C86.7073 287.131 86.2687 277.802 87.1127 273.411Z"
                                fill="#B0E6EA"/>
                        </g>
                        <path id="Vector 2"
                              d="M103.139 101.162C68.7967 102.777 94.1086 154.075 94.1086 154.075C136.47 142.178 170.608 156.481 170.608 156.481C170.608 156.481 196.86 107.562 165.079 103.503C135.299 97.3245 125.18 100.126 103.139 101.162Z"
                              fill="#CEF3F5"/>
                        <path id="Vector 4"
                              d="M103.139 101.162C68.7967 102.777 121.207 130.365 121.207 130.365C163.569 118.469 170.608 156.481 170.608 156.481C170.608 156.481 196.86 107.562 165.079 103.503C135.299 97.3245 125.18 100.126 103.139 101.162Z"
                              fill="#BCE6E9"/>
                        <ellipse id="Ellipse 1" cx="88.7777" cy="35.3994" rx="10.5" ry="3.5"
                                 transform="rotate(-25.8814 88.7777 35.3994)" fill="#D9D9D9"/>
                        <ellipse id="Ellipse 3" cx="171.337" cy="310.719" rx="7.99171" ry="2.6639"
                                 transform="rotate(-15.497 171.337 310.719)" fill="#D9D9D9"/>
                        <ellipse id="Ellipse 4" cx="82.6168" cy="305.964" rx="7.99171" ry="2.6639"
                                 transform="rotate(23.6411 82.6168 305.964)" fill="#D9D9D9"/>
                        <ellipse id="Ellipse 2" cx="181.555" cy="38.1642" rx="10.5" ry="3.5"
                                 transform="rotate(20.723 181.555 38.1642)" fill="#D9D9D9"/>
                    </g>
                </svg>
                <svg className="moving-car-yellow" viewBox="0 0 267 343" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="yellow-car">
                        <path id="Vector 1"
                              d="M64.0031 250.298C63.8766 266.121 68.9094 294.276 71.4417 306.375C77.9635 322.201 174.243 324.766 182.475 311.548C189.062 300.973 193.177 268.9 194.411 254.185C189.821 236.362 190.034 214.559 190.714 205.885L193.948 128.634L208.568 130.76C210.04 121.206 198.956 119.957 193.23 120.526L194.291 98.4155C198.056 85.9326 199.194 62.2428 195.857 55.9091C194.924 54.1386 197.982 40.9733 195.458 37.8442C188.287 28.9519 178.786 29.0234 174.69 28.6157C152.131 20.4092 123.357 19.6609 98.0546 25.7278C88.609 24.6411 77.2795 33.12 75.6837 36.6842L76.0234 52.6884C71.9904 57.5602 73.2595 81.6822 74.3981 93.1341L72.7794 116.959C64.104 115.656 57.2565 117.164 57.9754 125.271L72.6112 123.758C72.0168 143.833 70.754 188.158 70.4586 204.86C70.1633 221.562 66.0319 242.111 64.0031 250.298Z"
                              fill="#2F58EB"/>
                        <path id="Vector 6"
                              d="M64.0031 250.298C63.8766 266.121 68.9094 294.276 71.4417 306.375C77.9635 322.201 174.243 324.766 182.475 311.548C189.062 300.973 193.177 268.9 194.411 254.185C189.821 236.362 190.034 214.559 190.714 205.885L193.948 128.634L208.568 130.76C210.04 121.206 198.956 119.957 193.23 120.526L194.291 98.4155C198.056 85.9327 199.194 62.2428 195.857 55.9091C194.924 54.1386 197.982 40.9734 195.458 37.8442C188.287 28.9519 178.786 29.0234 174.69 28.6157C164.314 26.8073 157.301 31.4216 175.926 58.9096C190.827 80.8999 160.301 127.176 143.176 147.566C150.715 166.396 160.26 209.14 138.135 229.48C110.479 254.904 77.4354 211.211 81.7812 252.576C85.2578 285.669 71.3777 264.846 64.0031 250.298Z"
                              fill="#293BDC"/>
                        <g id="Vector 3">
                            <path
                                d="M91.5206 194.791C88.4291 215.221 82.3272 256.37 82.6517 257.525C77.8062 247.668 76.6826 225.79 76.7265 216.084L79.6638 128.529L89.9378 165.113C91.4519 170.505 91.6239 187.145 91.5206 194.791Z"
                                fill="#D2F8FB"/>
                            <path
                                d="M183.823 210.936C183.602 223.463 177.851 250.698 175.004 262.751C174.214 245.142 172.514 205.803 172.039 189.317C171.565 172.832 181.814 143.986 186.998 131.624C186.032 152.842 184.045 198.41 183.823 210.936Z"
                                fill="#D2F8FB"/>
                            <path
                                d="M87.1127 273.411L90.657 249.045C130.437 261.244 167.022 250.97 167.022 250.97C167.022 250.97 170.099 275.242 169.086 280.512C167.82 287.1 172.287 286.365 151.858 293.14C131.429 299.916 105.847 292.039 95.2138 289.313C86.7073 287.131 86.2687 277.802 87.1127 273.411Z"
                                fill="#D2F8FB"/>
                        </g>
                        <g id="Vector 5">
                            <path
                                d="M183.823 210.936C183.602 223.463 177.851 250.698 175.004 262.751C174.214 245.142 172.514 205.803 172.039 189.317C171.565 172.832 181.575 191.833 181.575 191.833C181.575 191.833 184.045 198.41 183.823 210.936Z"
                                fill="#B0E6EA"/>
                            <path
                                d="M87.1127 273.411C87.1127 273.411 89.0218 276.51 123.394 269.454C157.767 262.398 167.022 250.97 167.022 250.97C167.022 250.97 170.099 275.242 169.086 280.512C167.82 287.1 172.287 286.365 151.858 293.14C131.429 299.916 105.847 292.039 95.2138 289.313C86.7073 287.131 86.2687 277.802 87.1127 273.411Z"
                                fill="#B0E6EA"/>
                        </g>
                        <path id="Vector 2"
                              d="M103.139 101.162C68.7967 102.777 94.1086 154.075 94.1086 154.075C136.47 142.178 170.608 156.481 170.608 156.481C170.608 156.481 196.86 107.562 165.079 103.503C135.299 97.3245 125.18 100.126 103.139 101.162Z"
                              fill="#CEF3F5"/>
                        <path id="Vector 4"
                              d="M103.139 101.162C68.7967 102.777 121.207 130.365 121.207 130.365C163.569 118.469 170.608 156.481 170.608 156.481C170.608 156.481 196.86 107.562 165.079 103.503C135.299 97.3245 125.18 100.126 103.139 101.162Z"
                              fill="#BCE6E9"/>
                        <ellipse id="Ellipse 1" cx="88.7777" cy="35.3994" rx="10.5" ry="3.5"
                                 transform="rotate(-25.8814 88.7777 35.3994)" fill="#D9D9D9"/>
                        <ellipse id="Ellipse 3" cx="171.337" cy="310.719" rx="7.99171" ry="2.6639"
                                 transform="rotate(-15.497 171.337 310.719)" fill="#D9D9D9"/>
                        <ellipse id="Ellipse 4" cx="82.6168" cy="305.964" rx="7.99171" ry="2.6639"
                                 transform="rotate(23.6411 82.6168 305.964)" fill="#D9D9D9"/>
                        <ellipse id="Ellipse 2" cx="181.555" cy="38.1642" rx="10.5" ry="3.5"
                                 transform="rotate(20.723 181.555 38.1642)" fill="#D9D9D9"/>
                    </g>
                </svg>
            </div>

        </div>
    );
};

export default LoaderCar;