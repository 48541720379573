import React, { FC, useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ListGroup from "react-bootstrap/ListGroup";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import { CaseStatus } from "../models/CaseStatus";
import {StatusCodes} from "http-status-codes";
import {UserMarker} from "../models/UserMarker";
import {toast} from "react-toastify";
import Modal from "react-bootstrap/Modal";

import "../styles/markerManager.css";
import {getBackendUrl} from "../scripts/utils";
import {getUserAccessToken} from "../scripts/storageUtils";

interface Props {
    initialMarker: UserMarker | undefined;
    onChangeMarker: (newState: string, files?: File[]) => Promise<StatusCodes>;
}

const MarkerManager: FC<Props> = ({ initialMarker, onChangeMarker }) => {
    const [markerStatus, setMarkerStatus] = useState<string | undefined>(initialMarker?.status);
    const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

    function  handleStateChange (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>): void {
        const target = event.target as HTMLSelectElement;
        setMarkerStatus(target.value);
    }

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const MAX_FILE_SIZE_MB = 15;
        const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

        let files = Array.from(event.target.files || []);

        if (files.length > 0) {
            const filteredFiles = files.filter(file => {
                if (Object.values(initialMarker?.documents || {}).filter(x => x.fileName === file.name).length > 0) {
                    alert(`The file ${file.name} already exists. Please upload a different file.`);
                    if (fileInputRef.current) {
                        fileInputRef.current.value = "";
                    }

                    return false;
                }
                if (file.size > MAX_FILE_SIZE_BYTES) {
                    alert(`The file ${file.name} is larger than ${MAX_FILE_SIZE_MB}MB. Please upload a smaller file.`);
                    if (fileInputRef.current) {
                        fileInputRef.current.value = "";
                    }

                    return false;
                }
                return true;
            });

            if (filteredFiles.length > 0) {
                files = uploadedFiles.concat(filteredFiles);
                setUploadedFiles(files);
            }
        }
    };

    const removeFile = (index: number) => {
        const newUploadedFiles = [...uploadedFiles];
        newUploadedFiles.splice(index, 1);
        setUploadedFiles(newUploadedFiles);
    };

    const handleSaveChanges = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (markerStatus || uploadedFiles.length > 0) {
            const status = await onChangeMarker(markerStatus || "", uploadedFiles || new Array<File>());

            if (status === StatusCodes.OK) {
                setUploadedFiles([]);

                if (fileInputRef.current) {
                    fileInputRef.current.value = "";
                }
            }
        }
    };

    async function deleteMarker() {
        if (!initialMarker?.id) return;

        try {
            const userAccessToken = getUserAccessToken();

            if (!userAccessToken) {
                toast.error("Nie jesteś zalogowany lub twój token dostępu wygasł");
                setShowDeleteConfirm(false);
                return;
            }

            const response = await fetch(`${getBackendUrl("deleteIssue")}?id=${initialMarker.id}&accessToken=${userAccessToken}`, {
                method: "DELETE"
            });

            if (response.status === 200) {
                toast.success("Usunięto zgłoszenie");

                window.location.reload();
            } else {
                toast.error("Nie udało się usunąć zgłoszenia.");
            }
        } catch (error) {
            toast.error("Wystąpił błąd podczas usuwania.");
        }

        setShowDeleteConfirm(false);
    }


    return (
        <>
            <Form className="marker-manager" onSubmit={handleSaveChanges}>
                <Form.Group as={Row} className="align-items-center form-group">
                    <Form.Label column sm={4}>Zmień status:</Form.Label>
                    <Col sm={8}>
                        <Form.Control
                            as="select"
                            value={markerStatus}
                            onChange={handleStateChange}
                        >
                            {
                                CaseStatus.asArray().map((value, i) => (
                                    <option key={i} value={value}>{value}</option>))
                            }
                        </Form.Control>
                    </Col>
                </Form.Group>
                <Form.Group as={Row} controlId="formFileMultiple form-group">
                    <Col sm={12}>
                        <label className="custom-file-upload">
                            <input
                                type="file"
                                multiple
                                ref={fileInputRef}
                                accept=".doc,.docx,.pdf,.txt"
                                onChange={handleFileUpload}
                                style={{ display: 'none' }}
                            />
                            Dodaj pliki
                        </label>
                    </Col>
                </Form.Group>

                <ListGroup>
                    {uploadedFiles.map((file, index) => (
                        <ListGroup.Item key={index} style={{ color: "white", position: "relative" }}>
                            {file.name}
                            <Button
                                variant="danger"
                                style={{
                                    position: "absolute",
                                    top: "0",
                                    right: "0",
                                    fontSize: "0.7rem",
                                    padding: "0.2rem 0.5rem"
                                }}
                                onClick={() => removeFile(index)}
                            >
                                X
                            </Button>
                        </ListGroup.Item>
                    ))}
                </ListGroup>

                <Row style={{marginTop: '10px'}}>
                    <Col className="d-flex justify-content-end">
                        <Button variant="primary" type="submit">
                            Zapisz
                        </Button>
                    </Col>
                    <Col xs="auto">
                        <Button variant="outline-danger" onClick={() => setShowDeleteConfirm(true)}>
                            <FontAwesomeIcon icon={faTrash} />
                        </Button>
                    </Col>
                </Row>
            </Form>

            {showDeleteConfirm && (
                <Modal show onHide={() => setShowDeleteConfirm(false)} centered style={{backgroundColor: "rgba(128, 128, 128, 0.5)"}}>
                    <Modal.Header closeButton>
                        <Modal.Title>Potwierdź</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Czy na pewno chcesz usunąć to zgłoszenie?
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowDeleteConfirm(false)}>Przerwij</Button>
                        <Button variant="danger" onClick={deleteMarker}>Usuń</Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
};

export default MarkerManager;
