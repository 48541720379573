export class CaseStatus {
    static reported = "zgłoszony";
    static inReview = "rozpatrywany";
    static accepted = "przyjęty";
    static rejected = "odrzucony";
    static closed = "zamknięty";

    static asArray() {
        return [this.reported, this.inReview, this.accepted, this.rejected, this.closed];
    }
}
