import Form from "react-bootstrap/Form";
import ReCAPTCHA from "react-google-recaptcha";
import React, {useEffect, useRef, useState} from "react";
import {toast} from "react-toastify";
import {getBackendUrl} from "../../scripts/utils";
import LoaderCar from "../LoaderCar";

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY as string;
const CAPTCHA_ERROR_MSG = "Wystąpił błąd podczas weryfikacji Captcha. Spróbuj ponownie później.";

interface ReCaptchaProps {
    onCaptchaChange: (value: boolean) => void;
    error?: boolean;
}

export const ReCaptcha: React.FC<ReCaptchaProps> = ({ onCaptchaChange, error }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [captchaValid, setCaptchaValid] = useState<boolean>(false);

    const recaptchaRef = useRef<ReCAPTCHA>(null);

    useEffect(() => {
        const timer = setTimeout(() => {
            if (captchaValid) {
                resetCaptcha();
            }
        }, 10000);

        return () => clearTimeout(timer);
    }, [captchaValid]);

    const resetCaptcha = () => {
        if (recaptchaRef.current) {
            recaptchaRef.current.reset();
        }
        setCaptchaValid(false);
        onCaptchaChange(false);
    };

    const handleCaptchaChange = async (token: string | null) => {
        setLoading(true);
        try {
            const response = await fetch(getBackendUrl("verifyCaptcha"),
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({token})
                });

            const data = await response.json();

            if (!data.success) {
                resetCaptcha();
            }
            else {
                setCaptchaValid(data.success);
                onCaptchaChange(data.success);
            }

        } catch (error) {
            resetCaptcha();
            toast.error(CAPTCHA_ERROR_MSG);
        }
        setLoading(false);
    };

    return (
        <Form.Group className={"form-group"} style={{ marginTop: "30px" }}>
            {loading && <LoaderCar />}
            <ReCAPTCHA
                onExpired={() => onCaptchaChange(false)}
                sitekey={RECAPTCHA_SITE_KEY}
                onChange={handleCaptchaChange}
                ref={recaptchaRef}
                className={error ? "is-invalid" : ""}
                theme={"dark"}
            />
            {error && <Form.Control.Feedback type='invalid'>
                Potwierdź, że nie jesteś robotem.
            </Form.Control.Feedback>}
        </Form.Group>
    );
};
