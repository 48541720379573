import React from "react";
import {ContextUser} from "../models/ContextUser";

type UserContextType = {
    user: ContextUser | null;
    setUser: React.Dispatch<React.SetStateAction<ContextUser | null>>;
    isAdmin: () => boolean;
}

const UserContext = React.createContext<UserContextType | undefined>(undefined);

export default UserContext;
