import React from "react";
import Form from "react-bootstrap/Form";
import { isValidEmail, isValidPhoneNumber } from "../../scripts/utils";


export const directPolishPhonePrefix = '+48';

interface MarkerFormProps {
    title: string;
    description: string;
    takenActionDescription: string;
    email: string;
    phoneNumber: string;
    attemptedSubmit: boolean;
    setTitle: (value: string) => void;
    setDescription: (value: string) => void;
    setTakenActionDescription: (value: string) => void;
    setEmail: (value: string) => void;
    setPhoneNumber: (value: string) => void;
}

const MarkerForm: React.FC<MarkerFormProps> = ({
    title,
    description,
    takenActionDescription,
    email,
    phoneNumber,
    attemptedSubmit,
    setTitle,
    setDescription,
    setTakenActionDescription,
    setEmail,
    setPhoneNumber
}) => {

    const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        const inputPhone = event.target.value.replace(/\s/g, '');

        const formattedPhoneNumber = directPolishPhonePrefix + " " + inputPhone
            .replace(directPolishPhonePrefix, '')
            .replace(/\D/g, '')
            .replace(/(\d{3})(?=\d)/g, '$1 ');

        setPhoneNumber(formattedPhoneNumber);
    };

    return (
        <>
            <Form.Group className={"form-group"}>
                <Form.Label>Tytuł</Form.Label>
                <Form.Control
                    type="text"
                    name="title"
                    placeholder="Tytuł"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    isInvalid={attemptedSubmit && title.trim() === ""}
                    maxLength={200}
                    required
                />
                <Form.Control.Feedback type='invalid'>
                    Tytuł musi być uzupełniony.
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className={"form-group"}>
                <Form.Label>Opis zdarzenia</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={2}
                    name="Description"
                    placeholder="Opis"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    maxLength={2000}
                />
            </Form.Group>
            <Form.Group className={"form-group"}>
                <Form.Label>Podjęte działania</Form.Label>
                <Form.Control
                    as="textarea"
                    rows={1}
                    name="takenActionDescription"
                    placeholder="Opis podjętych działań"
                    value={takenActionDescription}
                    onChange={(e) => setTakenActionDescription(e.target.value)}
                    maxLength={2000}
                />
            </Form.Group>
            <Form.Group className={"form-group"}>
                <Form.Label>Email:</Form.Label>
                <Form.Control
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value.trim())}
                    isInvalid={attemptedSubmit && !isValidEmail(email)}
                    autoComplete={"email"}
                    maxLength={50}
                />
                <Form.Control.Feedback type='invalid'>
                    Podaj poprawny email
                </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className={"form-group"}>
                <Form.Label>Numer telefonu:</Form.Label>
                <Form.Control
                    type="tel"
                    placeholder="Numer telefonu"
                    value={phoneNumber}
                    onChange={handlePhoneNumberChange}
                    isInvalid={attemptedSubmit && !isValidPhoneNumber(phoneNumber)}
                    autoComplete={"tel-national"}
                    maxLength={15}
                />
                <Form.Control.Feedback type='invalid'>
                    Podaj poprawny numer telefonu.
                </Form.Control.Feedback>
            </Form.Group>
        </>
    );
};

export default MarkerForm;
