import React, {useEffect} from "react";
import { Marker, Tooltip } from "react-leaflet";
import L from "leaflet";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import icon from "leaflet/dist/images/marker-icon.png";
import { UserMarker } from "../models/UserMarker";
import {CaseStatus} from "../models/CaseStatus";

interface CustomMarkerProps {
    marker: UserMarker;
    handleMarkerClick: (marker: UserMarker) => void;
}

function CustomMarker({ marker, handleMarkerClick }: CustomMarkerProps) {
    const markerIcon = new L.Icon({
        iconUrl: icon,
        shadowUrl: iconShadow,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
    });
    
    const [date, setDate] = React.useState<string>("");

    useEffect(() => {
        setDate(new Date(marker.date).toLocaleDateString());
    }, [marker.date]);
    
    return (
        <Marker
            position={[marker.lat, marker.lng]}
            eventHandlers={{
                click: () => handleMarkerClick(marker),
            }}
            icon={markerIcon}
        >
            <Tooltip direction="top" offset={[0, -20]}>
                {marker.status === CaseStatus.reported ?
                    <div>Dodano zgłoszenie</div>
                    :
                    <div style={{wordWrap: "break-word", whiteSpace: "normal", maxWidth: "250px"}}>
                        <h6>{marker.title}</h6>
                        <strong>Opis:</strong> {marker.description}<br/>
                        <strong>Status:</strong> {marker.status}<br/>
                        <strong>Data dodania:</strong> {date}<br/>
                    </div>
                }

            </Tooltip>
        </Marker>
    );
}

export default CustomMarker;
