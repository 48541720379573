import React, {useEffect, useState} from "react";
import { Form, Button, Card } from "react-bootstrap";
import { FilterCaseStatus } from "../models/FilterCaseStatus";
import { UserMarker } from "../models/UserMarker";
import "../styles/filterComponent.css";
import {CaseStatus} from "../models/CaseStatus";

type FilterComponentProps = {
    markers: UserMarker[];
    filterState: string;
    setFilterState: (filterState: string) => void;
    onApplyFilter: (filteredMarkers: UserMarker[]) => void;
};

const CaseStatusExplanation: Record<string, string> = {
    [CaseStatus.accepted]: "Zgłoszenia przyjęte.",
    [CaseStatus.closed]: "Zgłoszenia zamknięte i rozwiązane lub zakończone.",
    [CaseStatus.inReview]: "Zgłoszenia rozpatrywane.",
    [CaseStatus.reported]: "Zgłoszenia zgłoszone",
    [CaseStatus.rejected]: "Zgłoszenia odrzucone i zamknięte.",
    [FilterCaseStatus.all]: "Wszystkie zgłoszenia"
};

const FilterComponent: React.FC<FilterComponentProps> = ({ markers, filterState, setFilterState, onApplyFilter }) => {
    const [status, setStatus] = useState<string>(FilterCaseStatus.all);
    const [title, setTitle] = useState<string>("");
    const [description, setDescription] = useState<string>("");

    const [showFilter, setShowFilter] = useState<boolean>(false);

    useEffect(() => {
        setStatus(filterState);
    }, [filterState]);

    const handleFilter = () => {
        let filteredMarkers = markers;
        if (status) {
            if (status !== FilterCaseStatus.all){
                filteredMarkers = filteredMarkers.filter(marker => marker.status === status);
            }

            setFilterState(status);
        }
        if (title) {
            filteredMarkers = filteredMarkers.filter(marker => marker.title.includes(title));
        }
        if (description) {
            filteredMarkers = filteredMarkers.filter(marker => marker.description.includes(description));
        }

        onApplyFilter(filteredMarkers);
    };

    return (
        <>
            <Button className="filter-button" onClick={() => setShowFilter(!showFilter)}>
                Filtry
            </Button>

            {showFilter && (
                <Card className="filter-card">
                    <Card.Header style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Card.Title style={{fontSize: "1rem"}}>Filtry</Card.Title>
                        <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowFilter(false)}/>
                    </Card.Header>
                    <Card.Body>
                        <Form>
                            <Form.Group>
                                <Form.Label>Status</Form.Label>
                                <Form.Control
                                    as="select"
                                    key={filterState}
                                    value={status}
                                    onChange={e => setStatus(e.target.value)}>
                                    {
                                        FilterCaseStatus.asArray().map((value, i) => (
                                            <option key={i} value={value} title={CaseStatusExplanation[value]}>{value}</option>))
                                    }
                                </Form.Control>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Tytuł</Form.Label>
                                <Form.Control type="text" value={title} onChange={e => setTitle(e.target.value)} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Opis</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={description}
                                    onChange={e => setDescription(e.target.value)}
                                />
                            </Form.Group>
                            <Button variant="primary" onClick={handleFilter}>
                                Filtruj
                            </Button>
                        </Form>
                    </Card.Body>
                </Card>
            )}
        </>
    );
};

export default FilterComponent;

