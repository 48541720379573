import {Document} from "./Document";

export type UserMarker = {
    id: string;
    lat: number;
    lng: number;
    title: string;
    description: string;
    takenActionDescription: string;
    email: string;
    phoneNumber: string;
    status: string;
    uploadedImages: string[];
    documents?: Document[];
    date: string;
    category: string;
}

export const DefaultUserMarker= {
    title: "",
    description: "",
    takenActionDescription: "",
    email: "",
    phoneNumber: "",
    uploadedImages: [],
    documents: [],
    category: "",
}