import {StatusCodes} from "http-status-codes";
import {getBackendUrl} from "./utils";

export const signInUser = async (email: string, password: string) =>
{
    const requestOptions: RequestInit = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({email: email, password: password})
    };

    return await fetch(getBackendUrl("login"), requestOptions);
};

export const signOutUser = async() => {

    const requestOptions: RequestInit = {
        credentials: "include"
    };

    try {
        const response = await fetch(`${getBackendUrl("logout")}`, requestOptions);
        if (!response.ok) {
            return new Response("Failed to delete cookie.", { status: StatusCodes.SERVICE_UNAVAILABLE });
        }

        return new Response("Successfully signed out", { status: StatusCodes.OK });
    } catch (error) {
        return new Response((error as Error)?.message, { status: StatusCodes.SERVICE_UNAVAILABLE });
    }
};